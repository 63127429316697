.project {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
  
.project h1 {
    color: #211A1D;
    font-size: 35px;
}
.project img {
    max-width: 50%;
    border-radius: 10px;
}
  
.project p {
    font-size: 20px;
    width: 60%;
    color: #211A1D;
}
  
.project svg {
    font-size: 60px;
    color: #211A1D;
}

.icon {
    margin-top: 1vh;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 480px) {
    .project h1 {
        font-size: 8vw;
    }

    .project p {
        font-size: 3vw;
    }

}