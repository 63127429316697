.home {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
    color: #211A1D;
}  

.logo {
    max-height: 50%;
    margin-top: -15%;
    margin-bottom: -2%;
}

.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: calc(100vh - 100px);
}

.intro h2 {
    font-size: 6vw;
    width: 100%;
    margin-bottom: 0;
    margin-top: -1rem;
}

.intro h3 {
    font-size: 2.75vw;
    width: 80%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.intro p {
    font-size: 2vw;
    margin-top: 0.5rem;
}

.skills {
    text-align: center;
    padding: 50px 0;
}

.skills h3 {
    font-size: 1.75rem;
    margin-bottom: 20px;
}

.skill-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.skills-column {
    margin: 0 20px;     
}

.skills-column h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.skills-column ul {
    list-style: none;
    padding: 0;
}

.skills-column li {
    font-size: 1.25rem;
    margin-bottom: 5px;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 480px) {
    .intro h2 {
        font-size: 8vw;
    }

    .intro h3 {
        font-size: 2.5vw;
    }

    .intro p {
        font-size: 2.5vw;
    }

    .skills-column {
        margin: 20px 0;
    }
}

