.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  color: #211A1D;
  background-color: #DFDBCD;
}

body {
  padding: 0;
  margin: 0;
}